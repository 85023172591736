@import "variable";
@import "mixin";

/* ======================================================================
 p_top
====================================================================== */
// l_main
.l_container {
  position: relative;
  z-index: 0;
}

// l_main_visual_top
.l_main_visual_top {
  position: relative;
  z-index: 2;
  height: 100vh;
  .mv_ttl {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 2;
  }
  .kv_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    .item {
      width: 100%;
      height: 100vh;
    }
    .photo {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);

      @include sp {
        bottom: 10px;
      }
      @include pc_tab {
        bottom: 18px;
      }
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      content: "";

      @include sp {
        height: 160px;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
        );
        background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
      }
      @include pc_tab {
        height: 249px;
        background-image: url(#{$img_dir}/common/gradation_1.png);
        background-repeat: repeat-x;
      }
    }
  }
  .slick-dots {
    position: absolute;
    right: 20px;
    z-index: 3;
    display: flex;
    justify-content: flex-end;

    @include sp {
      bottom: 10px;
    }
    @include pc_tab {
      bottom: 18px;
    }
    li {
      display: block;
      width: 8px;
      height: 8px;
      background: $color_1;
      color: transparent;
      transition: transform .3s;
      + li {
        margin: 0 0 0 7px;
      }
      &.slick-active {
        border: 1px solid $color_1;
        background: $white;
        transform: rotate(45deg);
      }
    }
  }
  .txt_box {
    position: absolute;
    z-index: 2;

    @include pc_tab {
      bottom: 48px;
      left: 50px;
      width: 563px;
    }
    @include sp {
      bottom: 44px;
      left: 20px;
      width: calc(100% - 40px);
    }
    .txt_ttl {
      @include pc_tab {
        margin: 0 0 30px;
      }
      @include sp {
        margin: 0 0 20px;
        width: 206px;
      }
    }
  }

  .top_mv_news_box {
    position: absolute;
    z-index: 2;

    @include pc_tab {
      bottom: 60px;
      left: 50px;
      display: flex;
      align-items: stretch;
      width: 509px;

      &__ttl {
        flex: 0 0 auto;
        margin-right: 15px;
        padding-right: 20px;
        border-right: 1px solid rgba(255, 255, 255, .2);
      }
      &__items {
        flex: 1 1;
      }
      &__item {
        &:not(:first-of-type) {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px dashed rgba(255, 255, 255, .2);
        }
      }
      &__infbox {
        display: flex;
        align-items: flex-start;
      }
      &__cat {
        margin-right: 12px;
        padding-right: 12px;
        border-right: 1px solid rgba(255, 255, 255, .2);
      }
      &__itemttl {
        transition: color .2s;
      }
      &__link {
        &:hover {
          .top_mv_news_box__itemttl {
            color: $color_1;
          }
        }
      }
    }
    @include sp {
      bottom: 71px;
      left: 20px;
      width: calc(100% - 40px);
      &__ttl {
        flex: 0 0 auto;
        margin-right: 0;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
      }
      &__infbox {
        display: flex;
        align-items: flex-start;
      }
      &__cat {
        margin-right: 12px;
        padding-right: 12px;
        border-right: 1px solid rgba(255, 255, 255, .2);
      }
      &__item {
        &:not(:first-of-type) {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px dashed rgba(255, 255, 255, .2);
        }
      }
    }
  }
}

.sec {
  &._1 {
    position: relative;
    z-index: -1;
    overflow: hidden;

    @include hide_scrollbar;
    @include sp {
      padding: 90px 0 0;
    }
    @include pc_tab {
      padding: 147px 0 0;
    }
    &.active {
      @include hide_scrollbar;

      &::before {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        content: "";

        @include sp {
          background-image: url(#{$img_dir}/p_top/sp/sec1_bg.jpg);
        }
        @include pc_tab {
          background-image: url(#{$img_dir}/p_top/sec1_bg.jpg);
        }
      }
    }
    .bg_deco_1 {
      position: absolute;
      z-index: 1;
      background-position: top left;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity .1s;

      mix-blend-mode: color-dodge;
      @include sp {
        top: -90px;
        left: 0;
        width: 100vw;
        height: 100vw;
        background-image: url(#{$img_dir}/p_top/sp/sec1_deco_top.png);
      }
      @include pc_tab {
        top: -6.8vw;
        left: 0;
        width: 67.7vw;
        height: 68.8vw;
        background-image: url(#{$img_dir}/p_top/sec1_deco_top.png);
      }
    }
    .bg_deco_2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 68.1vw;
      height: 54.5vw;
      background-position: top left;
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;

      mix-blend-mode: color-dodge;
      @include sp {
        top: auto;
        right: 0;
        bottom: 43px;
        left: auto;
        width: 100vw;
        height: 82.7vw;
        background-image: url(#{$img_dir}/p_top/sp/sec1_deco_bottom.png);
      }
      @include pc_tab {
        top: auto;
        right: 0;
        bottom: .5vw;
        left: auto;
        width: 68.1vw;
        height: 54.5vw;
        background-image: url(#{$img_dir}/p_top/sec1_deco_bottom.png);
      }
    }
    &.active {
      .bg_deco_1,
      .bg_deco_2 {
        opacity: 1;
      }
    }
    .inner {
      position: relative;
      z-index: 2;
      max-width: 1200px;

      @include sp {
        margin: 100px auto 0;
        padding: 0 20px 100px;
      }
      @include pc_tab {
        margin: 70px auto 0;
        padding: 0 0 271px;
      }
      .u_paragraph {
        & + & {
          margin: 30px 0 0;
        }
      }
      .sign {
        margin: 40px 0 0;
        .img {
          margin: 0 0 0 18px;
        }
      }
    }
    .bg_deco {
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        background-image: url(#{$img_dir}/p_top/sec1_before.png);
        background-position: left bottom;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        content: "";

        @include sp {
          top: -90px;
          height: 90px;
        }
        @include pc_tab {
          top: -147px;
          height: 339px;
        }
      }
      &::after {
        @include sp {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 239px;
          background: rgb(0, 0, 0);
          background: -moz-linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
          );
          content: "";
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
        }
      }
    }
  }
  &._2 {
    position: relative;
    z-index: 3;

    @include pc_tab {
      margin: -146px auto 0;
    }
    &::before {
      @include pc_tab {
        position: absolute;
        top: -100px;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 249px;
        /*background: rgb(0,0,0);
                background:    -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                background:         linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);*/
        background-image: url(#{$img_dir}/common/gradation_1.png);
        background-repeat: repeat-x;
        content: "";
      }
    }
    &::after {
      position: absolute;
      left: 0;
      z-index: -1;
      display: block;
      background: $black;
      content: "";

      @include sp {
        top: 0;
        width: 100%;
        height: calc(100% + 25.1vw + 58px);
      }
      @include pc_tab {
        top: 149px;
        width: 100%;
        height: calc(100% + 40px);
      }
    }
    .bg_deco {
      position: relative;
      width: 100%;
      height: 100%;
      &::before {
        position: absolute;
        z-index: 0;
        background-position: top left;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        content: "";

        @include sp {
          top: 62px;
          left: 0;
          width: 100vw;
          height: 78.4vw;
          background-image: url(#{$img_dir}/p_top/sp/sec2_deco_left.png);
        }
        @include pc_tab {
          top: 113px;
          left: 0;
          width: 640px;
          height: 502px;
          background-image: url(#{$img_dir}/p_top/sec2_deco_left.png);
        }
      }
    }
    .u_content_size {
      position: relative;

      @include pc_tab {
        display: flex;
      }
    }
    .txt_wrap {
      @include pc_tab {
        margin: 0 82px 0 0;
        width: 418px;
      }
      @include sp {
        display: flex;
        margin: 0 0 30px;
      }
      .c_ttl_box {
        @include sp {
          margin: 0 0 0 20px;
          width: 97px;
        }
      }
      .txt_box {
        @include pc_tab {
          margin: 43px 0 0;
        }
        @include sp {
          margin: 0 0 0 39px;
          width: calc(100% - 156px);
        }
      }
    }
    .img_wrap {
      @include sp {
        position: relative;
        margin-left: -20px;
        width: 100vw;
      }
      .photo {
        position: relative;
        &._1 {
          @include pc_tab {
            top: 97px;
          }
          @include sp {
            width: 45.3vw;
          }
        }
        &._2 {
          position: absolute;
          right: 0;

          @include pc_tab {
            top: -58px;
          }
          @include sp {
            top: 28px;
            width: 48vw;
          }
        }
        & + .photo {
          margin-left: 80px;
        }
      }
    }
  }
  &._3 {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: $black;

    @include pc_tab {
      margin: 167px auto 0;
    }
    @include sp {
      margin: calc(25.1vw + 58px) auto 0;
    }
    &::before {
      @include pc_tab {
        position: absolute;
        bottom: -780px;
        left: 0;
        z-index: 0;
        width: 830px;
        height: 940px;
        background-image: url(#{$img_dir}/p_top/sec4_deco_top.png);
        background-position: top left;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        content: "";
      }
    }
    .u_content_size {
      @include sp {
        padding: 0;
      }
    }
    .link {
      position: relative;
      display: block;
      color: $white;
    }
    .photo {
      width: 100%;
    }
    .note {
      position: absolute;
      top: 50%;
      z-index: 2;
      width: 100%;
      transform: translateY(-50%);
    }
    .icon {
      display: block;
      width: 62px;
      height: 62px;

      @include sp {
        margin: 0 auto 10px;
      }
      @include pc_tab {
        margin: 0 auto 16px;
      }
    }
    .icon_ex {
      margin-left: 6px;
      width: 9px;
      height: 9px;
      vertical-align: baseline;
    }
  }
  &._4 {
    position: relative;
    overflow: hidden;
    background: $black;

    @include pc_tab {
      padding: 120px 0 0;
    }
    @include sp {
      padding: 100px 0 0;
    }
    &::before {
      position: absolute;
      z-index: 0;
      background-position: top left;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: 0;
        left: 0;
        width: 65.3vw;
        height: 72vw;
        background-image: url(#{$img_dir}/p_top/sp/sec4_deco_top.png);
      }
      @include pc_tab {
        top: -160px;
        left: 0;
        width: 830px;
        height: 940px;
        background-image: url(#{$img_dir}/p_top/sec4_deco_top.png);
      }
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      background: $black;
      content: "";
    }
    .c_sheet_menu {
      position: relative;
      z-index: 1;

      @include pc_tab {
        margin: 60px 0 0;
      }
      @include sp {
        padding: 40px 0 0;
        &::after {
          position: absolute;
          bottom: -36px;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 36px;
          background: $black;
          content: "";
        }
      }
    }
  }
  &._5 {
    position: relative;
    background: $black;

    @include pc_tab {
      padding: 120px 0 0;
    }
    @include sp {
      padding: 100px 0 0;
    }
    &::before {
      position: absolute;
      z-index: 0;
      background-position: top left;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: 187px;
        right: 0;
        width: 340px;
        height: 270px;
        background-image: url(#{$img_dir}/p_top/sp/sec5_deco_bottom.png);
      }
      @include pc_tab {
        top: 86px;
        right: 0;
        width: 786px;
        height: 458px;
        background-image: url(#{$img_dir}/p_top/sec5_deco_bottom.png);
      }
    }
    .c_ttl_box {
      position: relative;
      z-index: 1;
    }
    .c_menu {
      @include pc_tab {
        margin: 60px 0 0;
      }
      @include sp {
        margin: 50px 0 0;
      }
    }
    .bnr_wrap {
      position: relative;
      z-index: 3;
      display: block;
    }
    .bnr_link {
      display: inline-block;
      .bnr {
        max-width: 100%;

        @include sp {
          margin: 26px 13px 0 22px;
          width: calc(100% - 36px);
        }
        @include pc_tab {
          margin: 60px 0 0;
        }
      }
    }
  }
  &._6 {
    position: relative;
    background: $black;

    @include pc_tab {
      padding: 120px 0 0;
    }
    @include sp {
      padding: 100px 0 0;
    }
    &::before {
      position: absolute;
      z-index: 0;
      background-position: top left;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: -77px;
        left: 0;
        width: 100vw;
        height: 78.4vw;
        background-image: url(#{$img_dir}/p_top/sp/sec6_deco_right.png);
      }
      @include pc_tab {
        top: -130px;
        right: 0;
        width: 640px;
        height: 502px;
        background-image: url(#{$img_dir}/p_top/sec6_deco_right.png);
      }
    }
    .content_wrap {
      display: flex;
      align-items: flex-start;

      @include sp {
        flex-direction: row-reverse;
        flex-wrap: wrap;
      }
    }
    .img_wrap {
      @include sp {
        order: 2;
        margin: 40px 0 0;
      }
      .photo {
        @include sp {
          width: 100%;
        }
        @include pc_tab {
          width: 57.3vw;
        }
      }
    }
    .txt_wrap {
      display: flex;

      @include sp {
        align-items: flex-start;
        flex-direction: row-reverse;
        order: 1;
        width: 100%;
      }
      @include pc_tab {
        flex-wrap: wrap;
        padding: 50px 83px;
        width: 42.7vw;
      }
      .ttl {
        @include sp {
          width: 160px;
        }
        @include pc_tab {
          width: 100%;
        }
      }
      .c_ttl_1 {
        @include sp {
          margin: 0 auto;
        }
        @include pc_tab {
          margin: 0 auto 43px;
          width: 120px;
          height: 152px;
        }
      }
      .txt {
        @include sp {
          padding: 0 0 0 20px;
          width: calc(100% - 160px);
        }
        @include pc_tab {
          width: 100%;
        }
      }
    }
    .u_content_size {
      @include sp {
        padding: 0 35px;
      }
    }
  }
  &._7 {
    position: relative;
    z-index: 2;
    background: $black;

    @include pc_tab {
      padding: 120px 0 0;
    }
    @include sp {
      padding: 100px 0 0;
    }
    .u_content_size {
      max-width: 1170px;

      @include pc_tab {
        display: flex;
        align-items: flex-start;
      }
    }
    .c_ttl_box {
      @include pc_tab {
        margin: 0 75px 0 0;
        width: 211px;
      }
      .c_btn_1 {
        @include pc_tab {
          padding: 10px 30px;
          width: 100%;
        }
      }
    }
    .info_box {
      @include sp {
        margin: 50px 0;
      }
      @include pc_tab {
        width: calc(100% - 286px);
      }
    }
  }
  &._8 {
    position: relative;
    z-index: 1;

    @include pc_tab {
      margin: -40px 0 0;
      padding: 120px 0 0;
    }
    @include sp {
      padding: 100px 0 0;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      background: $black;
      content: "";

      @include sp {
        height: 100%;
      }
      @include pc_tab {
        height: calc(100% - 100px);
      }
    }
    &::after {
      position: absolute;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      background-position: top left;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        bottom: -119px;
        height: 119px;
        background-image: url(#{$img_dir}/p_top/sp/sec8_bg.png);
      }
      @include pc_tab {
        bottom: -239px;
        height: 339px;
        background-image: url(#{$img_dir}/p_top/sec8_bg.png);
      }
    }
    .u_content_size {
      max-width: 1110px;

      @include pc_tab {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .c_ttl_box {
      @include pc_tab {
        margin: 0 118px 0 0;
      }
    }
    .content {
      @include sp {
        margin: 50px auto 0;
      }
      @include pc_tab {
        margin: 0 0 20px;
        width: calc(100% - 220px);
      }
    }
    .c_btn_2_wrap {
      width: 100%;
    }
  }
}
.c_cta {
  &._1 {
    position: relative;
  }
}
